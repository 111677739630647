import { useState } from 'react';

const colorHEX = {
	success: '#43ac6a',
	danger: '#f14668'
};
const MessageComponent = ({ title = '', body = '', type = 'info', handleClose }) => (
	<article className={`message is-${type}`}>
		<div className="message-body flex flex-space-between">
			{body} &nbsp;
			<button
				className="delete right"
				aria-label="delete"
				onClick={handleClose}
				style={{ backgroundColor: colorHEX[type] }}
			/>
		</div>
	</article>
);

export default MessageComponent;
