function InputField(props) {
	const { type, name, id, error, icon = '', classes = '', label = name } = props;

	const typeOfinput = type === 'checkbox' ? 'is-checkradio' : 'input';
	return (
		<div className="field">
			<label className="label">{label.replace(/./, x => x.toUpperCase())}</label>
			<div className="control has-icons-left">
				{type == 'textarea' ? (
					<textarea className={`textarea ${classes}`} {...props} />
				) : (
					<input className={`${typeOfinput} ${classes}`} {...props} />
				)}
				{type === 'checkbox' ? <label htmlFor={id}>Check me</label> : ''}
				{icon && (
					<span className="icon is-small is-left">
						<i className={icon} />
					</span>
				)}
			</div>
			{error && <span className="has-text-danger">{error}</span>}
		</div>
	);
}

export default InputField;
